/**
 * Adicionar mensagens que podem ser consumidas pela aplicação de maneira geral!
 */

const alertMessages = {
  USER_CREATED: 'Usuário criado com sucesso!',
  USER_UPDATED: 'Usuário atualizado com sucesso!',
  USER_DELETED: 'Usuário excluido com sucesso!'
}

const permissionsErrorMessages = {
  USERS_CREATE: 'Você não tem permissão para criar usuário! Contate um adminstrador da instituição'
}

export { alertMessages, permissionsErrorMessages }
