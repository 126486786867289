<template>
    <v-container>
      <base-breadcrumb :title="page.title" :breadcrumbs="breadcrumbs"></base-breadcrumb>
      <base-card heading="Criar usuário">
        <form-user-steps @save="save($event)"/>
      </base-card>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import { alertMessages, permissionsErrorMessages } from '@/constants/messages'
import { handleError } from '@/utils/helpers'

export default {
  name: 'CreateUser',
  data: () => ({
    page: {
      title: 'Criação de usuário'
    },
    value: {},
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Usuários',
        disabled: false,
        to: '/users'
      },
      {
        text: 'Criação de usuário',
        disabled: true,
        to: '/users/create'
      }
    ],
    organizationId: '',
    alertMessages,
    permissionsErrorMessages
  }),
  created () {
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
  },
  methods: {
    ...mapMutations(['setLoading']),
    handleError,
    save (e) {
      this.setLoading(true)
      const payload = {
        ...e.user,
        ...(!e.user.is_needed_to_change_password && { is_needed_to_change_password: false }),
        accept_terms: true,
        is_super_admin: false,
        is_enabled: true
      }
      this.$axios.post(`/organizations/${this.organizationId}/users`, payload)
        .then(async response => {
          const id = response.data.metadata.id
          if (e.org_roles.length) {
            await this.addUserInOrganization(id, e.org_roles)
          }

          if (e.school_roles.length) {
            await this.addUserInSchools(id, e.school_roles)
          }

          if (e.course_roles.length) {
            await this.addUserInCourses(id, e.course_roles)
          }
        })
        .then(() => {
          this.$store.dispatch('alert', { msg: this.alertMessages.USER_CREATED })
          this.gotToListUsers()
        })
        .catch(error => {
          this.handleError(error)
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    cancel () {
      this.gotToListUsers()
    },
    async addUserInOrganization (id, acl) {
      const payload = {
        user: { id: id },
        roles: acl
      }
      await this.$axios.post(`/organizations/${this.organizationId}/people`, payload)
        .catch(error => {
          this.handleError(error)
        })
    },
    async addUserInSchools (id, roles) {
      roles.map(async role => {
        const payload = {
          user: { id: id },
          roles: role.role
        }
        await this.$axios.post(`/organizations/${this.organizationId}/schools/${role.school}/people`, payload)
          .catch(error => {
            this.handleError(error)
          })
      })
    },
    async addUserInCourses (id, roles) {
      roles.map(async role => {
        const payload = {
          user: { id: id },
          roles: role.role
        }
        await this.$axios.post(`/organizations/${this.organizationId}/schools/${role.school}/courses/${role.course}/people`, payload)
          .catch(error => {
            this.handleError(error)
          })
      })
    },
    gotToListUsers () {
      this.$router.push({ path: '/users' })
    }
  }
}
</script>
